<template>
    <div class="select">
        <div class="table-container">
            <div class="button_container">
                学期：
                <el-select size="small" v-model="semesterId" placeholder="请选择" class="select_container input" @change="onSearch">
                    <el-option
                        v-for="item in this.$store.state.chinese.semester.list"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                    </el-option>
                </el-select>
                <el-button size="small" type="primary" class="select_container" @click="onSearch">查询
                </el-button>
                <el-button size="small" type="primary" class="select_container" @click="onReset">重置
                </el-button>
                <el-button size="small" type="primary" class="select_container" @click="addBook" v-if="hasAddBook">添加教材
                </el-button>
                <div class="upload_container" v-if="hasAddBook">一键上传<input size="small" type="file" class="upload_input" value="excel" @change="onExcel"></div>
                <div class="upload_container" v-if="hasAddBook">资源上传<input size="small" type="file" class="upload_input" value="excel" multiple
                                                                           @change="onResource"></div>
                <el-button type="primary" size="small" @click="onGenerate" class="add_button select_container" v-if="hasAddBook">生成数据
                </el-button>
                <el-button type="primary" size="small" @click="downloadFile" class="add_button select_container" v-if="hasAddBook">下载数据
                </el-button>
            </div>
            <el-table border :data="this.$store.state.chinese.book.list">
                <el-table-column type="index" :index="indexCompute" label="序号" align="center"
                                 width="60px"></el-table-column>
                <el-table-column prop="semesterName" label="学期" align="center" width="100px"></el-table-column>
                <el-table-column prop="publishing" label="出版社名称" align="center">
                </el-table-column>
                <el-table-column prop="name" label="教材" align="center">
                </el-table-column>
                <el-table-column label="封面" align="center">
                    <template slot-scope="scope">
                        <img :src="getBookUrl(scope.row.coverUrl)" alt="" style="width: 105px;height: 150px">
                    </template>
                </el-table-column>
                <el-table-column label="操作" align="center" width="300px">
                    <template slot-scope="scope">
                        <el-link type="primary" icon="el-icon-delete" @click="deleteBook(scope.row)" v-if="hasDeleteBook">删除</el-link>&nbsp;&nbsp;
                        <el-link type="primary" icon="el-icon-s-operation" @click="updateBook(scope.row)" v-if="hasPutBook" style="margin-left: 10px;">修改</el-link>&nbsp;&nbsp;
                        <el-link type="primary" icon="el-icon-s-operation" @click="gounit(scope.row)" style="margin-left: 10px;">前往该教材单元</el-link>&nbsp;&nbsp;
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="pagination-container">
            <el-pagination class="pagination afterClearfix"
                           @size-change="handleSizeChange"
                           @current-change="handleCurrentChange"
                           :current-page="pagination.page+1"
                           :page-sizes="[10, 20, 30, 50]"
                           :page-size="pagination.size"
                           layout="total, sizes, prev, pager, next, jumper"
                           :total="this.$store.state.chinese.book.total"
                           style="float: right">
            </el-pagination>
        </div>
        <router-view/>
    </div>
</template>

<script>
    import axios from '@/libs/axios'
    import {checkPermission, setData} from "@/utils/cookies";


    export default {
        name: 'List',
        data() {
            return {
                semesterId: undefined,//学期id
                pagination: {
                    page: 0,
                    size: 10,
                },
                dialogVisible: false,//添加弹框
                modifyDialogVisible: false,//修改弹框
                hasAddBook: checkPermission("addBook"),//添加教材权限
                hasPutBook: checkPermission("putBook"),//修改教材权限
                hasDeleteBook: checkPermission("deleteBook"),//删除教材的权限
                downloadUrl: '',

            }
        },
        mounted() {
            this.semesterList();
            this.getBookList();

        },
        methods: {
            // 获取学期列表
            semesterList() {
                this.$store.dispatch('chinese/semesterList')
            },

            // 获取教材列表
            getBookList() {
                this.$store.dispatch("chinese/bookList", {
                    'semesterId': this.semesterId,
                    'page': this.pagination.page,
                    'size': this.pagination.size
                });
            },

            // 查询
            onSearch() {
                this.pagination.page = 0;
                this.getBookList();
            },

            // 重置
            onReset() {
                this.semesterId = 0;
                this.getBookList();
            },

            // 添加教材
            addBook() {
                this.$router.push('/chinese/book/add');
            },

            // 一键上传
            onExcel(e) {
                console.log(e.target.files[0]);
                axios.upload("/v1/web/word/upload", e.target.files[0]).then(response => {
                }).catch((e) => {
                    console.log(e)
                });
            },

            // 上传资源文件
            onResource(e) {
                console.log(e.target.files);
                for (let i = 0; i < e.target.files.length; i++) {
                    axios.upload("/v1/web/file/book", e.target.files[i]).then(response => {
                        console.log("上传成功 :" + e.target.files[i].name);
                    }).catch((e) => {
                        console.log(e)
                    });
                }
            },

            // 生成数据
            onGenerate() {
                this.$store.dispatch("chinese/generateFile", {
                    'semesterId': this.semesterId,
                    'bookId': 0
                })
            },

            // 下载数据
            downloadFile() {
                this.downloadUrl = '';
                this.$store.dispatch("chinese/downloadFile").then((data) => {
                    if (!data.saving) {
                        this.downloadUrl = axios.getUrl(data.excelFileUrl);
                        this.saveShareContent(this.downloadUrl, (data.semesterName || "") + "-" + (data.bookname || "") + '.xlsx')
                    }
                });

            },

            // 删除教材
            deleteBook(row) {
                this.$store.dispatch("chinese/deleteBook", {
                    'id': row.id,
                }).then((data) => {
                    this.getBookList();
                });
            },

            // 修改教材
            updateBook(row) {
                setData('bookInfo', row)
                this.$router.push('/chinese/book/update');
            },

            // 前往该单元
            gounit(row) {
                setData('goUnitInfo', row)
                this.$router.push('/chinese/unit/unit');
            },

            // 获取资源地址
            getBookUrl(url) {
                return axios.getBookUrl(url);
            },

            // 保存下载的excel
            saveShareContent(content, fileName) {
                let downLink = document.createElement('a')
                downLink.download = fileName
                downLink.href = content
                // 链接插入到页面
                document.body.appendChild(downLink)
                downLink.click()
                // 移除下载链接
                document.body.removeChild(downLink)
            },

            // 列表序号
            indexCompute(index) {
                return index + 1 + this.pagination.page * this.pagination.size;
            },

            // 页大小改变
            handleSizeChange(size) {
                this.pagination.size = size;
                this.getBookList();
            },

            // 页码改变
            handleCurrentChange(page) {
                this.pagination.page = page - 1;
                this.getBookList();
            },
        }
    }
</script>


<style scoped lang="scss">
    .select {
        padding: 0 15px;
    }

    .button_container {
        width: 100%;
        margin-bottom: 20px;
        font-size: 14px;
        align-items: center;

        .select_container {
            margin-right: 30px;
            margin-bottom: 30px;
        }

        .input {
            width: 150px;
        }

        .school_input {
            display: inline-block;
            width: 300px;
        }

        .upload_container {
            display: inline-flex;
            width: 80px;
            height: 32px;
            position: relative;
            overflow: hidden;
            background-color: #409EFF;
            border-radius: 5px;
            color: #ffffff;
            line-height: 32px;
            justify-content: center;
            margin-right: 30px;
            font-size: 12px;
        }

        .upload_input {
            display: inline-block;
            position: absolute;
            top: 0;
            left: -500px;
            width: 580px;
            height: 32px;
            border: none;
            outline: none;
        }
    }


</style>
